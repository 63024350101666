<svelte:options customElement={{ tag: "bt-interests", shadow: "none" }} />

<script lang="ts">
	import { onMount } from 'svelte';
	import { ArrowLeft } from 'lucide-svelte';

	import ActionButton from '$shared/action-button/ActionButton.svelte'
	import PillCheckbox from '$shared/pill-checkbox/PillCheckbox.svelte';
	import InterestsRole from '$comp/interests/InterestsRole.svelte';

	// Translations
	export let myRoleText;
	export let myInterestsText;
	export let backToHomeText;
	export let backToDashboardText;
	export let skipText;
	export let selectedRoleText;
	export let selectedInterestsText;
	export let saveAndReturnToHomeText;
	export let selectText;
	export let selectedText;
	export let individualContributorText;
	export let individualContributorBlurbText;
	export let teamManagerText;
	export let teamManagerBlurbText;
	export let seniorLeaderText;
	export let seniorLeaderBlurbText;
	export let selectYourTopThreeText;
	export let allText;
	export let leadingYourselfText;
	export let leadingATeamText;
	export let leadingTheBusinessText;
	export let totalSelectedText;

	let backLinkPath: string = '/';
	let backLinkText: string = backToHomeText;

	let possibleRoles: any[] | string = [];
	let possibleInterests: any[] = [];

	let allTabs: Element;
	let interestSelection: Element;

	let selectedInterests: any[] = [];
	let selectedRole: string = '';

	let isLoading = true;

	const getUserInterestsAndRole = async() => {
		const response = await fetch('/interests.json', {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      credentials: 'same-origin',
    })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    } else {
      const data = await response.json();
      return data;
    }
	}

	const setInterests = async() => {
		const response = await fetch('/interests', {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      credentials: 'same-origin',
      body: JSON.stringify({
      	learner_interests: selectedInterests.map(interestObject => interestObject.name),
      	learner_type: selectedRole
     	})
    })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    } else {
      const data = await response.json();
      return data;
    }
	}

	const yourselfInterests = [
		"Lifelong Learning",
	  "Self-Motivation",
	  "Career Development",
	  "Creativity",
	  "Critical Thinking",
	  "Problem Solving",
	  "Emotional Intelligence",
	  "Communication",
	  "Health and Wellness"
   ]

	const teamInterests = [
	  "Leadership",
    "Management",
    "Executive Presence"
  ]

  const businessInterests = [
	  "Human Resources",
	  "Diversity, Equity, & Inclusion",
	  "Diversity, Equity, Inclusion, & Belonging",
	  "Digital Fluency",
	  "Innovation",
	  "Design Thinking",
	  "Risk Management",
	  "Sales",
	  "Marketing",
	  "Customer Experience"
  ]

	let yourselfCount: Number = 0;
	let teamCount: Number = 0;
	let businessCount: Number = 0;

	const handleTabClick = (event) => {
		let targetButton = event.target.tagName == 'SPAN' ? event.target.parentElement : event.target;
		if (targetButton.getAttribute('aria-selected') == 'true') {
			return;
		}
		let targetPanel = interestSelection.querySelector(`#${targetButton.getAttribute('aria-controls')}`)
		allTabs.querySelectorAll('button').forEach(button => button.setAttribute('aria-selected', 'false'));
		targetButton.setAttribute('aria-selected', true);
		interestSelection.querySelectorAll('[role="tabpanel"]').forEach(panel => {
			panel.setAttribute('hidden', 'true');
		});
		targetPanel?.removeAttribute('hidden');
	}

	const handlePillCheckboxChanged = (event) => {
		let interestObj = possibleInterests.find(interest => interest.id_string === event.detail.value);
		let interestIndex = selectedInterests.findIndex(interest => interest.id === interestObj.id);

		if (interestIndex >= 0) {
			selectedInterests = selectedInterests.filter(interest => interest.id !== interestObj.id);
		} else {
			selectedInterests = [...selectedInterests, interestObj];
		}
	}

	$: {
		yourselfCount = yourselfInterests.filter(yourselfInt => {
			return selectedInterests.find(selectedInt => selectedInt.name === yourselfInt)
		}).length;
	}
	$: {
		teamCount = teamInterests.filter(teamInt => {
			return selectedInterests.find(selectedInt => selectedInt.name === teamInt)
		}).length;
	}
	$: {
		businessCount = businessInterests.filter(businessInt => {
			return selectedInterests.find(selectedInt => selectedInt.name === businessInt)
		}).length;
	}

	$: saveInterestsButtonDisabled = (selectedInterests.length < 3 || !selectedRole)

	const isInterestDisabled = (interestId, selectedInts) => {
		if (isLoading) {
			return true;
		}
		else {
			return false
		}
	}

	const handleSaveInterests = () => {
		setInterests().then(() => {
			window.location.assign(window.location.origin);
		});
	}

	const handleRoleChanged = (event) => {
		selectedRole = event.detail;
	}

	const tabs = [
		{ id: 'all', title: allText },
		{ id: 'yourself', title: leadingYourselfText },
		{ id: 'team', title: leadingATeamText },
		{ id: 'business', title: leadingTheBusinessText }
	];

	onMount(() => {
		getUserInterestsAndRole().then((data) => {
			possibleRoles = data.possible_types.map(role => {
				return { label: role, value: role }
			})
			possibleInterests = data.possible_interests;
			selectedRole = data.learner_type;
			selectedInterests = data.possible_interests.filter(interest => {
				return data.learner_interests.indexOf(interest.name) >= 0;
			});
			isLoading = false;
		});
		if (document.referrer.match('dashboard')) {
			backLinkPath = '/dashboard';
			backLinkText = backToDashboardText
		}
	});

	$: progressBarProgress = Math.min(Math.max(selectedInterests.length + (selectedRole ? 1 : 0), 0), 4);

</script>

<section class="interests-page">

	<section class="header">
		<a class="link-back" href={backLinkPath}><ArrowLeft class="w-4 h-4 inline-block relative top-[1px]"/>{backLinkText}</a>
		<a class="link-skip" href={backLinkPath}>{skipText}</a>
	</section>


	<section class="my-role">
		<h2>{myRoleText}</h2>
		{#if !isLoading}
			<!-- <RadioGroup options={possibleRoles} selected={selectedRole} on:radio-changed={handleRadioChanged} /> -->
			<InterestsRole 
				selectText={selectText} 
				selectedText={selectedText}
				individualContributorText={individualContributorText}
				individualContributorBlurbText={individualContributorBlurbText}
				teamManagerText={teamManagerText}
				teamManagerBlurbText={teamManagerBlurbText}
				seniorLeaderText={seniorLeaderText}
				seniorLeaderBlurbText={seniorLeaderBlurbText}
				possibleRoles={possibleRoles} 
				selectedRole={selectedRole} 
			on:select-role={handleRoleChanged} />
		{/if}
	</section>

	<section class="my-interests">
		<h2>{myInterestsText}</h2>
		<div class="progress">
			<span class="progress__selected">{selectedRoleText}<span class="progress__count">
				{#if selectedRole}
					<span class="text-sky-blue">1 / 1</span>
				{:else}
					<span class="text-neutral-500">0 / 1</span>
				{/if}
			</span></span>
			<span class="progress__selected">{selectedInterestsText} 
				{#if selectedInterests.length >= 3}
					<span class="progress__count text-sky-blue">{selectedInterests.length} / 3</span>
				{:else}
					<span class="progress__count text-neutral-500">{selectedInterests.length} / 3</span>
				{/if}
			</span>
			<div class={`progress__bar progress__bar--${progressBarProgress}`}></div>
			<ActionButton isDisabled={saveInterestsButtonDisabled} design="primary" on:click={handleSaveInterests} buttonClasses="w-full">{saveAndReturnToHomeText}</ActionButton>
		</div>
	</section>

	<div class="flex flex-wrap" style="min-height: 2.5rem; margin-bottom: .25rem">
		{#each selectedInterests as interest}
			<PillCheckbox label={interest.text} value={interest.id_string} accentColor={interest.capability_color} design="solid" on:checkbox-changed={handlePillCheckboxChanged} isChecked={selectedInterests.find(selectedInt => selectedInt.id === interest.id)} />
		{/each}
	</div>
	<div class="select-interests">
		<h2>{selectYourTopThreeText}</h2>

		<div class="interest-selection-wrapper" bind:this={interestSelection}>
			<div class="interest-selection-tablist" role="tablist" aria-label="Interest categories" bind:this={allTabs}>
				{#each tabs as tab}
					<button class="interest-selection-tab" id={`tab-${tab.id}`} on:click={handleTabClick} role="tab" aria-selected={tab.id === 'all' ? 'true' : 'false'} aria-controls={`panel-${tab.id}`} tabindex="0">
						<span class="interest-selection-tab__title">{tab.title}</span>
						<span class="interest-selection-tab__count">
							{#if tab.id === 'all'}
								{totalSelectedText}: {selectedInterests.length}
							{:else if tab.id === 'yourself'}
								{yourselfCount} {selectedText}
							{:else if tab.id === 'team'}
								{teamCount} {selectedText}
							{:else if tab.id === 'business'}
								{businessCount} {selectedText}
							{/if}
						</span>
					</button>
				{/each}
			</div>
			{#if !isLoading}
				<div id="panel-all" role="tabpanel" tabindex="0" aria-labelledby="tab-all" class="interest-selection-panel">
					{#each possibleInterests as interest}
						<PillCheckbox label={interest.text} value={interest.id_string} accentColor={interest.capability_color} design="solid" on:checkbox-changed={handlePillCheckboxChanged} isChecked={selectedInterests.find(selectedInt => selectedInt.id === interest.id)} isDisabled={isInterestDisabled(interest.id, selectedInterests)}/>
					{/each}
				</div>
				<div id="panel-yourself" role="tabpanel" tabindex="0" aria-labelledby="tab-yourself" class="interest-selection-panel" hidden>
					{#each possibleInterests as interest}
						{#if yourselfInterests.indexOf(interest.name) >= 0}
							<PillCheckbox label={interest.text} value={interest.id_string} accentColor={interest.capability_color} design="solid" on:checkbox-changed={handlePillCheckboxChanged} isChecked={selectedInterests.find(selectedInt => selectedInt.id === interest.id)} isDisabled={isInterestDisabled(interest.id, selectedInterests)}/>
						{/if}
					{/each}
				</div>
				<div id="panel-team" role="tabpanel" tabindex="0" aria-labelledby="tab-team" class="interest-selection-panel" hidden>
					{#each possibleInterests as interest}
						{#if teamInterests.indexOf(interest.name) >= 0}
							<PillCheckbox label={interest.text} value={interest.id_string} accentColor={interest.capability_color} design="solid" on:checkbox-changed={handlePillCheckboxChanged} isChecked={selectedInterests.find(selectedInt => selectedInt.id === interest.id)} isDisabled={isInterestDisabled(interest.id, selectedInterests)}/>
						{/if}
					{/each}
				</div>
				<div id="panel-business" role="tabpanel" tabindex="0" aria-labelledby="tab-business" class="interest-selection-panel" hidden>
					{#each possibleInterests as interest}
						{#if businessInterests.indexOf(interest.name) >= 0}
							<PillCheckbox label={interest.text} value={interest.id_string} accentColor={interest.capability_color} design="solid" on:checkbox-changed={handlePillCheckboxChanged} isChecked={selectedInterests.find(selectedInt => selectedInt.id === interest.id)} isDisabled={isInterestDisabled(interest.id, selectedInterests)}/>
						{/if}
					{/each}
				</div>
			{/if}
		</div>
	</div>

</section>

<style>
	.interests-page {
		margin: 2rem;
		@media (max-width: theme("screens.sm")) {
			margin: 1rem;
		}
	}

	.header {
		display: flex;
		justify-content: space-between;
	}
	.header a {
		display: inline-block;
	}
	a.link-back {
		display: flex;
		align-items: center;
		&:hover {
			text-decoration: underline;
		}
	}
	a.link-skip {
		font-weight: bold;
		text-decoration: underline;
	}

	.my-interests {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding-bottom: .75rem;
		margin-bottom: 1.25rem;
		border-bottom: 2px solid theme("colors.neutral.200");

		& h2 {
			font-weight: bold;
			padding-right: theme('spacing.4');
		}	
	}

	.my-role {
		display: block;
		padding-top: 3rem;
		padding-bottom: .75rem;
		& h2 {
			font-weight: bold;
			border-bottom: 2px solid theme("colors.neutral.200");
			padding-bottom: .75rem;
			margin-bottom: theme('spacing.4');
		}
	}

	.progress {
		width: 14rem;
	}

	.progress__selected {
		display: inline-block;
		width: 100%;
		text-align: right;
		font-size: theme('fontSize.xs');
		font-weight: bold;
		text-transform: uppercase;
	}
	.progress__count {
		display: inline-block;
		width: 2rem;
	}
	.progress__bar {
		position: relative;
		width: 100%;
		height: theme('spacing.1');
		border-radius: 1px;
		margin-top: theme('spacing.2');
		margin-bottom: theme('spacing.2');
		background: theme('colors.neutral.200');
		overflow: hidden;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			transform: translateX(-100%);
			background: theme('colors.sky-blue');
			transition: transform 100ms ease-in-out;
		}
	}
	.progress__bar--1:before {
		transform: translateX(-75%);
	}
	.progress__bar--2:before {
		transform: translateX(-50%);
	}
	.progress__bar--3:before {
		transform: translateX(-25%);
	}
	.progress__bar--4:before {
		transform: translateX(0);
	}

	.select-interests {
		h2 {
			font-weight: bold;
			font-size: theme('fontSize.xl');
			margin-bottom: theme('spacing.4');
		}
	}

	.interest-selection-wrapper {
		background: theme('colors.neutral.200');
		border: 1px solid theme('colors.neutral.300');
		border-radius: 4px;
		padding: theme('spacing.8') theme('spacing.6');
		@media (max-width: 600px) {
			padding: theme('spacing.4');
		}
	}

	.interest-selection-tablist {
		@media (max-width: 940px) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;
		}
	}

	.interest-selection-tab {
		padding-left: theme('spacing.8');
		padding-top: theme('spacing.4');
		padding-bottom: theme('spacing.2');
		margin-right: theme('spacing.2');
		background: white;
		text-align: left;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		&[aria-selected="true"] {
			cursor: default;
		}
		&[aria-selected="false"] {
			background: theme('colors.neutral.100');
		}
		width: 12rem;
		padding-left: theme('spacing.4');
		&:first-of-type, &:nth-child(3) {
			padding-left: theme('spacing.4');
		}
		@media (min-width: 480px) {
			&:first-of-type, &:nth-child(3) {
				padding-left: theme('spacing.8');
			}
		}
		@media (max-width: 940px) {
			width: auto;
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
		@media (min-width: 1265px) {
			width: 17rem;
			padding-left: theme('spacing.8');
		}
	}
	.interest-selection-tab__title {
		display: block;
		font-weight: bold;
		text-align: left;
		padding-right: theme('spacing.2');
	}
	.interest-selection-tab__count {
		display: inline-block;
		text-align: left;
		font-size: theme('fontSize.sm');
		color: theme('colors.sky-blue');
	}

	.interest-selection-panel {
		display: flex;
		flex-wrap: wrap;
		background: white;
		padding-top: theme('spacing.10');
		padding-left: theme('spacing.8');
		padding-right: theme('spacing.8');
		&[hidden] {
			display: none;
		}
		@media (max-width: 480px) {
			padding-left: theme('spacing.4');
			padding-right: theme('spacing.4');
		}
	}

	:global(.interests-page .pill-checkbox) {
		margin-right: theme('spacing.4');
		margin-bottom: theme('spacing.4');
	}
	:global(.interest-selection-panel .pill-checkbox) {
		margin-bottom: theme('spacing.6');
	}


</style>