<script lang="ts">
	import { createEventDispatcher } from 'svelte';

	import ActionButton from '$shared/action-button/ActionButton.svelte'
	import Icon from '$shared/icons/Icon.svelte';

	export let possibleRoles = [];
	export let selectedRole;

	export let selectText;
	export let selectedText;
	export let individualContributorText;
	export let individualContributorBlurbText;
	export let teamManagerText;
	export let teamManagerBlurbText;
	export let seniorLeaderText;
	export let seniorLeaderBlurbText;

	const dispatch = createEventDispatcher();

	const dispatchSelectedRole = (selectedRole) => {
		dispatch('select-role', selectedRole)
	}
</script>

<section class="role-section">
	{#each possibleRoles as possibleRole}
		<div class={`role-card ${possibleRole.value === selectedRole ? 'role-card--selected' : ''}`}>
			{#if possibleRole.value === 'Individual Contributor'}
				<h3>{individualContributorText}</h3>
				<p>{individualContributorBlurbText}</p>
			{:else if possibleRole.value === 'Team Manager'}
				<h3>{teamManagerText}</h3>
				<p>{teamManagerBlurbText}</p>
			{:else if possibleRole.value === 'Senior Leader'}
				<h3>{seniorLeaderText}</h3>
				<p>{seniorLeaderBlurbText}</p>
			{/if}
			<ActionButton design="primary-small" buttonClasses='!justify-center' on:click={dispatchSelectedRole(possibleRole.value)}>
				{#if possibleRole.value === selectedRole}
					<Icon icon="Check" size="small" iconClass="relative left-1 top-px"/>
					<span class="mr-4">{selectedText}</span>
				{:else}
					{selectText}
				{/if}
			</ActionButton>
		</div>
	{/each}
</section>

<style>
	.role-section {
		display: flex;
		gap: theme('spacing.4');
	}
	.role-card {
    background: white;
    max-width: 16rem;
    min-width: 12rem;
    border-radius: 10px;
    padding: theme('spacing.4');
    display: flex;
    flex-direction: column;
    & h3 {
    	font-weight: 600;
    }
    & p {
    	font-size: theme('fontSize.sm');
    	margin-top: theme('spacing.6');
    	margin-bottom: theme('spacing.4');
    	flex-grow: 1;
    }
    button {
    	align-self: flex-end;
    	font-weight: normal;
    }
	}
	.role-card--selected {
		background: theme('colors.neutral.100');
	}
	@media (max-width: 680px) {
		.role-section {
			flex-direction: column;
		}
		.role-card {
			max-width: initial;
			width: 100%;
		}
	}
</style>